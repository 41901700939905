<template>
  <div id="app">
    <div class="content-container">
      <div class="title-container">
        <h1 class="title">MATERIALITYS</h1>
      </div>
      <p class="subtitle">Guess. Rank. Share(s).</p>
      <p class="coming-fall">coming this fall</p>
    </div>
    <p class="colophon">Made in New York</p>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Koulen', sans-serif;
  height: 100%;
  background-color: white; /* You can change the background color here */
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  text-align: center;
  position: relative;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
}

.title {
  font-size: 5rem;
  font-family: 'Koulen', cursive;
}

.subtitle {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
  font-weight: 300;
  margin-top: 0.5rem;
}

.coming-fall {
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
  font-weight: 300;
  margin-top: 0.2rem;
  color: #888; /* Lighter color */
  font-variant: small-caps; /* Small caps style */
}

.colophon {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
  font-weight: 300;
  position: absolute;
  bottom: 20px;
}
</style>
